import { template as template_3e75a0a564284b43a85bb2715a84741b } from "@ember/template-compiler";
const FKLabel = template_3e75a0a564284b43a85bb2715a84741b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
